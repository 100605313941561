import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AppService } from '../app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pipeline, PipelineInfo } from 'src/app/utils/types';
import * as moment from 'moment';
import { Position, RESOURCES, Type } from 'src/app/utils/const';
import { interval, Subscription } from 'rxjs';
import { AppAuthGuardService } from 'src/app/data/service/app-auth-guard.service';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { CreateDeploymentComponent } from '../create-deployment/create-deployment.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { error } from 'console';
var Convert = require('ansi-to-html');
var convert = new Convert();
interface LogEntry {
  duration: string;
  logs: string;
  status: string;
  numero: number;
}

interface Log {
  key: string;
  duration: string;
  logs: any;
  status: string;
  numero: number;
  tmpLog?: any;
}
@Component({
  selector: 'app-app-log',
  templateUrl: './app-log.component.html',
  styleUrls: ['./app-log.component.scss'],
})
export class AppLogComponent implements OnInit, OnDestroy {
  @Input('ansiToHtml') ansiString: string | undefined;

  // Running

  selectedTab: number = 0;
  private intervalSubscription: Subscription;
  projectID: string = '';
  pipelineId: string = '';
  pipelineInfo: PipelineInfo;
  logsDataTable: Log[] = [];
  loadingLog: boolean = false;
  loadingPipeline: boolean = false;
  loadingApps = false;
  currentOpenLog = -1;

  tmpJobs: any = [];
  tmpLogs = [];
  loadJob = 0;

  isRerunJob = false;
  isDeployed = false;
  closeStep = false;

  pipelineStatus: boolean = false;

  applications: any = [];
  appOfEnv: any;

  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private router: Router,
    public auth: AppAuthGuardService,
    private service: AsideExtenderService
  ) {
    // this.isCollapsed[0] = true
  }
  ngOnInit(): void {
    const url: string = this.router.url;
    this.route.queryParams.subscribe(async (params) => {
      this.pipelineId = params['pipelineId'];
      this.projectID = params['projectId'];

      if (this.pipelineId && this.projectID) {
        this.getPipelineRunInfoById();
        this.intervalSubscription = interval(5000).subscribe(() => {
          if (url.includes('app-log')) {
            this.getPipelineRunInfoById();
          }
        });
      }
    });
  }

  getAllDeployApplication(projectID: string, pipelineID: string) {
    this.loadingApps = true;
    this.appService.getDeployApplication(pipelineID, projectID).subscribe(
      (response) => {
        this.applications = response;
        this.loadingApps = false;
      },
      (error) => {}
    );
  }

  ngOnDestroy(): void {
    this.intervalSubscription.unsubscribe();
  }
  getErrorFailedStatus(value: string): boolean {
    if (/fail(?:ed)?|error/i.test(value)) {
      return true;
    } else {
      return false;
    }
  }
  isCollapsed: boolean[] = Array(this.logsDataTable?.length).fill(false);

  toggleCollapse(index: number, close: boolean): void {
    this.currentOpenLog = index;
  }

  sortLogsDataTable(logs) {
    return Object.entries(logs)
      ?.map(([key, value]: [string, LogEntry]) => ({
        key,
        duration: value.duration,
        logs: value.logs,
        status: value.status.toLowerCase(),
        numero: value.numero,
      }))
      .sort((a, b) => a.numero - b.numero);
  }

  canReRunPipeline() {
    let canRerun = false;

    if (
      this.auth?.hasOrganizationRoles('super_admin') ||
      this.auth?.hasOrganizationRoles('owner+') ||
      this.auth?.hasOrganizationRoles('owner') ||
      this.auth?.hasProjectRoles('admin', this.projectID) ||
      this.auth?.hasProjectRoles('developer', this.projectID)
    ) {
      canRerun = true;
    }

    return canRerun;
  }

  async onDeployAppInAnEnvironment(): Promise<void> {
    this.isDeployed = true;
    const application = await this.getApplicationById(
      this.pipelineInfo.pipelineID,
      this.projectID
    );
    const config: any = {
      class: 'popup-right-side',
      initialState: { application: application },
    };
    this.modalService.show(CreateDeploymentComponent, config);
    this.isDeployed = false;
  }

  getApplicationById(appID: string, projectId: string) {
    return new Promise((resolve, reject) => {
      this.appService.getApplicationById(appID, projectId).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  onRerunPipeline() {
    if (this.canReRunPipeline()) {
      this.isRerunJob = true;
      this.appService.rerunPipeline(this.projectID, this.pipelineId).subscribe(
        (response: any) => {
          this.logsDataTable = [];
          this.tmpLogs = [];
          this.pipelineInfo.status = 'Running';
          this.intervalSubscription = interval(5000).subscribe(() => {
            this.getPipelineRunInfoById();
          });
        },
        (error) => {
          this.service.show({
            title: 'Rerun job',
            message: error.error,
            type: Type.ERROR,
            position: Position.TOP,
          });
        }
      );
    } else {
      this.service.show({
        title: 'Permission denied',
        message:
          'This profile is not authorized to run pipeline in this environment',
        type: Type.ERROR,
        position: Position.TOP,
      });
    }
  }

  getPipelineRunInfoById() {
    this.appService
      .getPipelineRunById(this.pipelineId, this.projectID)
      .subscribe(
        (response: PipelineInfo) => {
          this.makeStep(response);
        },
        (error) => console.log(error.message)
      );
  }

  displayDateForHuman(date: any) {
    if (date) {
      let parseToMoment = moment(date);
      return parseToMoment.fromNow();
    }
  }

  onBack() {
    this.router.navigate(['/app-details'], {
      queryParams: {
        id: this.pipelineInfo.pipelineID,
        projectId: this.projectID,
      },
    });
  }

  updateScroll(elementName) {
    if (!['Succeeded', 'Failed'].includes(this.pipelineInfo?.status)) {
      var element = document.getElementById(elementName);
      var main = document.querySelector('.main');
      if (element.scroll) {
        element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
      }
      if (main.scroll) {
        main.scroll({ top: main.scrollHeight, behavior: 'smooth' });
      }
    }
  }

  async makeStep(response: any) {
    this.pipelineInfo = response;

    if (this.tmpLogs.length == 0) {
      this.getAllDeployApplication(
        this.pipelineInfo.projectId,
        this.pipelineInfo.pipelineID
      );
    }

    const logs = this.sortLogsDataTable(response.logs);

    for (let index = 0; index < logs.length; index++) {
      let arrivedJob: Log = logs[index];

      if (!this.tmpLogs[index]) {
        this.tmpLogs.push('');
      }

      let existIndexJob = this.logsDataTable.findIndex(
        (log) => log.numero == arrivedJob.numero
      );

      if (existIndexJob === -1) {
        this.currentOpenLog = index;
        this.logsDataTable.push(arrivedJob);
      }

      let parseHTML = '';

      if (this.tmpLogs[index].length != arrivedJob?.logs.length) {
        this.tmpLogs[index] = arrivedJob?.logs;
        parseHTML = convert.toHtml(arrivedJob?.logs);
      }

      if (existIndexJob !== -1) {
        this.logsDataTable[existIndexJob].status = arrivedJob.status;
        this.logsDataTable[existIndexJob].duration = arrivedJob.duration;
      }

      setTimeout(() => {
        if (parseHTML.length > 0) {
          $('#pre' + index).html(parseHTML);
          this.updateScroll('pre' + index);
        }
      }, 2);

      if (arrivedJob.status == 'Completed') {
        await new Promise((resolve) => setTimeout(resolve, 4000));
      }
    }

    if (['Succeeded', 'Failed'].includes(this.pipelineInfo?.status)) {
      this.intervalSubscription.unsubscribe();
      this.isRerunJob = false;
    }
  }

  parseToHTML(html: string) {
    const htmlParse = $.parseHTML(html);
    return htmlParse;
  }
}
